<template>
    <path d="m440 6.5-416 239.9c-34.4 19.9-31.1 70.8 5.7 85.9l114.3 47.3v84.4c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1 111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6 8.2 0 16.3-2.1 23.6-6.2 12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zm-248 457.5v-64.6l36.6 15.1-36.6 49.5zm212.6-28.7-153.8-63.5 140.2-202.3c10.7-15.5-9.5-33.5-23.7-21.2l-211.5 184.3-107.8-44.6 416-240-59.4 387.3z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 512, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
